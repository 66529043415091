export const selectPrizeDraw = (state) => state?.prizeDraw;
export const selectPrizeDrawItems = (state) => state?.prizeDraw?.itens;
export const selectPrizeDrawModal = (state) => state?.prizeDraw?.modal;
export const selectPrizeDrawArea = (state) => state?.prizeDraw?.area;
export const selectPrizeDrawAreaXModel = (state) =>
  state?.prizeDraw?.area_x_modelo;
export const selectPrizeDrawClassification = (state) =>
  state?.prizeDraw?.classificacao;

export const selectPrizeDrawEscalonateCalendar = (state) =>
  state?.prizeDraw?.escalonateCalendar;
