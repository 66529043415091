import {
  PRIZE_DRAW_ADD,
  PRIZE_DRAW_CLOSE_MODAL,
  PRIZE_DRAW_DATES,
  PRIZE_DRAW_DEL,
  PRIZE_DRAW_INIT,
  PRIZE_DRAW_SET_MODAL,
  PRIZE_DRAW_UPD,
  PRIZE_DRAW_ESCALONATE_CALENDAR,
} from "./escalonada-actions";

const initialState = null;

export default function prizeDrawReducer(state = initialState, action) {
  switch (action.type) {
    case PRIZE_DRAW_INIT:
      return {
        ...state,
        ...action.data,
      };
    case PRIZE_DRAW_DATES:
      return {
        ...state,
        ...action.data,
      };
    case PRIZE_DRAW_SET_MODAL:
      return {
        ...state,
        modal: action.data,
      };
    case PRIZE_DRAW_CLOSE_MODAL:
      return {
        ...state,
        modal: null,
      };
    case PRIZE_DRAW_ADD:
      return {
        ...state,
        itens: [...state.itens, action.data],
        modal: null,
      };
    case PRIZE_DRAW_UPD:
      return {
        ...state,
        itens: state.itens.map((item) =>
          item.id === action.data.id ? action.data : item
        ),
        modal: null,
      };
    case PRIZE_DRAW_DEL:
      return {
        ...state,
        itens: state.itens.filter((item) => item.id !== action.data),
        modal: null,
      };
    case PRIZE_DRAW_ESCALONATE_CALENDAR:
      return {
        ...state,
        escalonateCalendar: action.data,
      };
    default:
      return state;
  }
}
