import React, { useCallback, useEffect, useState } from "react";
import { Calendario, Icone } from "../../../../components";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { prizeDrawEscalonateCalendar } from "../escalonada-actions";
import { useToggle } from "react-use";
import { selectPrizeDrawEscalonateCalendar } from "../escalonada-selectors";
import { ButtonBase, Chip } from "@mui/material";
import { useHistory } from "react-router-dom";
import EscalonadaCalendarioVerDia from "./escalonada-calendario-ver-dia";

const STR = {
  titleTotalEscalonatesToday: "Total de escalonadas no dia",
};

function EscalonadaCalendario() {
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  //
  useEffect(() => {
    dispatch(
      prizeDrawEscalonateCalendar(
        {
          mes: date.split("-")[1],
          ano: date.split("-")[0],
        },
        setWait
      )
    );
  }, [dispatch, setWait, date]);

  return <EscalonadaCalendarioVer dateInit={date} />;
}

const EscalonadaCalendarioVer = ({ wait, dateInit }) => {
  const history = useHistory();
  const escalonateCalendar = useSelector(selectPrizeDrawEscalonateCalendar);
  //
  const onViewEscalonateInDay = useCallback(
    (escalonates, day) => {
      history.push(EscalonadaCalendarioVerDia.rota, {
        escalonates,
        escalonateDay: day,
      });
    },
    [history]
  );
  //
  const renderEscalonate = useCallback(
    (day) => {
      if (escalonateCalendar && escalonateCalendar.hasOwnProperty(day)) {
        const escalonates = escalonateCalendar[day];
        return (
          <ButtonBase
            title={STR.titleTotalEscalonatesToday}
            sx={{ width: "100%", height: "56px" }}
            onClick={() => onViewEscalonateInDay(escalonates, day)}
          >
            <Chip
              icon={<Icone icone="Timeline" />}
              label={escalonates.length}
            />
          </ButtonBase>
        );
      }
      return null;
    },
    [onViewEscalonateInDay, escalonateCalendar]
  );
  return (
    <Calendario
      aguardar={wait}
      dataInicial={dateInit}
      render={renderEscalonate}
    />
  );
};

export default EscalonadaCalendario;
