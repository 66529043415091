import React from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import EscalonadaAddUpd from "./escalonada-add-upd";
import EscalonadaDel from "./escalonada-del";
import EscalonadaVer from "./escalonada-ver";
import EscalonadaCalendarioVer from "./calendario/escalonada-calendario-ver-dia";

function EscalonadaModal({ modal }) {
  let body;
  switch (modal.type) {
    case EscalonadaModal.modal.VIEW:
      body = <EscalonadaVer item={modal.data} />;
      break;
    case EscalonadaModal.modal.ADD:
      body = <EscalonadaAddUpd />;
      break;
    case EscalonadaModal.modal.DEL:
      body = <EscalonadaDel id={modal.data} />;
      break;
    case EscalonadaModal.modal.UPD:
      body = <EscalonadaAddUpd item={modal.data} />;
      break;
    default:
      body = <div>DEFAULT</div>;
  }
  return (
    <ContainerAdaptavel
      sx={{
        minHeight:
          modal.type === EscalonadaModal.modal.VIEW_CALENDAR ? "80vh" : "50vh",
      }}
    >
      {body}
    </ContainerAdaptavel>
  );
}

EscalonadaModal.modal = {
  VIEW: "VIEW",
  ADD: "ADD",
  DEL: "DEL",
  UPD: "UPD",
};

export default EscalonadaModal;
