import { useDispatch, useSelector } from "react-redux";
import { selectPrizeDrawItems } from "../escalonada-selectors";
import {
  FabExtend,
  FiltroPorPeriodo,
  ScrollInfinito,
} from "../../../../components";
import { Container, Paper, Stack } from "@mui/material";
import PrizeDrawItem from "../components/prize-draw-item";
import { useCallback, useEffect, useState } from "react";
import { prizeDrawDates, prizeDrawSetModal } from "../escalonada-actions";
import EscalonadaModal from "../escalonada-modal";
import { format } from "date-fns";
import PrizeDrawOptionsCad from "../components/prize-draw-options-cad";

const dateFrom = format(new Date(), "yyyy-MM-01");
const dateTo = format(new Date(), "yyyy-MM-dd");

const EscalonadaCadastro = () => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState(`${dateFrom}_${dateTo}`);
  //
  useEffect(() => {
    const [inDateFrom, inDateTo] = dates.split("_");
    dispatch(prizeDrawDates(() => {}, inDateFrom, inDateTo));
  }, [dispatch, dates]);

  return (
    <Container maxWidth={false}>
      <EscalonadaPainel dates={dates} setDates={setDates} />
      <EscalonadaCorpo />
    </Container>
  );
};

const EscalonadaPainel = ({ dates, setDates }) => {
  const dispatch = useDispatch();
  const onAdd = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.ADD,
      })
    );
  }, [dispatch]);

  ///
  return (
    <Stack direction="row" justifyContent="space-between">
      <FiltroPorPeriodo
        datas={dates}
        onClick={(datas) =>
          datas ? setDates(datas) : setDates(`${dateFrom}_${dateTo}`)
        }
        periodoDefault="periodo"
      />

      <Stack direction="row" alignItems="center" flex={1} gap={1}>
        <FabExtend color="success" onClick={onAdd} />
      </Stack>
    </Stack>
  );
};

const EscalonadaCorpo = () => {
  const items = useSelector(selectPrizeDrawItems);

  return (
    <ScrollInfinito
      itens={items || []}
      tamanho="80vh"
      render={(item) => (
        <Paper elevation={3} sx={{ p: 1, my: 1, mx: 0.1 }}>
          <PrizeDrawItem
            key={item.id}
            item={item}
            id={item.id}
            areaName={item.area_nome}
            modelsName={item.modelos}
            name={item.nome}
            mat={item.matricula}
            avatar={item.avatar}
            plant={item.planta}
            dateCreated={item.data_criacao}
            dateRegister={item.data_registro}
            status={item.status}
            pontuation={item.pontuacao}
            options={<PrizeDrawOptionsCad item={item} id={item.id} />}
          />
        </Paper>
      )}
      itensPorPagina={10}
    />
  );
};

export default EscalonadaCadastro;
