import {
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import React from "react";
import { Body1, Icone } from "../../../../components";
import { format, parseISO } from "date-fns";
import {
  getStatusColor,
  getStatusIcon,
  getStatusName,
} from "../escalonada-utils";

const STR = {
  areaName: "Área",
  modelsName: "Modelos",
  dateCreated: "Data de criação",
  dateRegister: "Data do registro",
  pontuationName: "Pontuação",
};

function PrizeDrawItem({
  areaName,
  modelsName,
  dateCreated,
  dateRegister,
  mat,
  avatar,
  status,
  name,
  pontuation,
  options,
}) {
  return (
    <ListItem secondaryAction={options}>
      <ListItemAvatar>
        <Avatar alt={name} src={avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack alignItems="flex-start">
            <Stack alignItems="end" direction="row" gap={1.5}>
              <Chip
                icon={<Icone icone={getStatusIcon(status)} />}
                label={getStatusName(status)}
                color={getStatusColor(status)}
              />
              <PrizeDrawPontuation pontuation={pontuation} />
            </Stack>
            <Body1>
              {mat} - {name}
            </Body1>
          </Stack>
        }
        secondary={
          <PrizeDrawItemSecondary
            areaName={areaName}
            modelsName={modelsName}
            dateCreated={dateCreated}
            dateRegister={dateRegister}
            pontuation={pontuation}
          />
        }
      />
    </ListItem>
  );
}

const PrizeDrawPontuation = ({ pontuation }) => {
  const colorBox = useTheme().palette.success.main;
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="end" gap={1}>
      <Body1>{STR.pontuationName}: </Body1>
      <Box
        sx={{
          p: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          minWidth: 30,
          borderRadius: 2,
          background: colorBox,
        }}
      >
        {pontuation}
      </Box>
    </Stack>
  );
};

const PrizeDrawItemSecondary = ({ areaName, modelsName, dateRegister }) => {
  return (
    <Stack gap={0.5}>
      <Body1>
        {STR.areaName}: {areaName}
      </Body1>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={0.5}>
          <Body1>{STR.modelsName}: </Body1>
          <Stack direction="row" gap={0.5}>
            {modelsName.map((model) => (
              <Chip
                key={model[0]}
                label={model[1]}
                size="small"
                color="primary"
              />
            ))}
          </Stack>
        </Stack>
        <Body1>
          {STR.dateRegister}: {format(parseISO(dateRegister), "dd/MM/yy")}
        </Body1>
      </Stack>
    </Stack>
  );
};

export default PrizeDrawItem;
