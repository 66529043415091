//
export const getStatusColor = (status) => {
  switch (status) {
    case "A":
      return "success";
    case "B":
      return "info";
    default:
      return "warning";
  }
};
export const getStatusIcon = (status) => {
  switch (status) {
    case "A":
      return "Timeline";
    case "B":
      return "Build";
    default:
      return "Help";
  }
};

export const getStatusName = (status) => {
  switch (status) {
    case "A":
      return "Aberto";
    case "B":
      return "Em vistoria";
    default:
      return "Pendente";
  }
};
