import { Container, List, Paper, Stack } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackgroundRouter from "../../../background-router";
import EscalonadaGoBack from "../components/escalonada-go-back";
import { H6 } from "../../../../components";
import { format, parseISO } from "date-fns";
import PrizeDrawItem from "../components/prize-draw-item";

const STR = {
  titlePageEscalonateCalendarDay: "ESCALONADAS DO DIA ",
};

function EscalonadaCalendarioVerDia() {
  const history = useHistory();
  const escalonates = history.location?.state?.escalonates || [];
  const escalonateDay = history.location?.state?.escalonateDay || "";

  return (
    <BackgroundRouter>
      <Container maxWidth={false}>
        <Stack direction="row" justifyContent="space-between">
          <EscalonadaGoBack />
          <H6>
            {STR.titlePageEscalonateCalendarDay}{" "}
            {format(parseISO(`${escalonateDay}`), "dd/MM/yyyy")}
          </H6>
          <div />
        </Stack>
        <List>
          {escalonates.map((ele, idx) => (
            <Paper sx={{ my: 1 }} elevation={3} key={ele.id}>
              <PrizeDrawItem
                item={ele.escalonada}
                id={ele.escalonada.id}
                areaName={ele.escalonada.area_nome}
                modelsName={ele.escalonada.modelos}
                name={ele.escalonada.nome}
                mat={ele.escalonada.matricula}
                avatar={ele.escalonada.avatar}
                plant={ele.escalonada.planta}
                dateCreated={ele.escalonada.data_criacao}
                dateRegister={ele.escalonada.data_registro}
                status={ele.escalonada.status}
                pontuation={ele.escalonada.pontuacao}
              />
            </Paper>
          ))}
        </List>
      </Container>
    </BackgroundRouter>
  );
}

EscalonadaCalendarioVerDia.rota = "/escalonada_calendario";

export default EscalonadaCalendarioVerDia;
